import styled from 'styled-components'

export const StyledIndex = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  h1 {
    margin: 0;
    color: #fff;

    span {
      display: block;
      text-transform: uppercase;
      font-size: var(--font-size__h3);
    }
  }

  .blocks {
    position: absolute;
    bottom: 50px;
    left: 50px;
  }

  .block {
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;

    a,
    button {
      background: none;
      display: inline-flex;
      align-items: center;
      padding: 20px 30px;
      color: #fff;
      line-height: 1.3;
      gap: 20px;
      cursor: pointer;
    }

    svg {
      width: 40px;
    }

    button {
      outline: 0;
      border: none;
      text-align: left;
    }

    span {
      display: block;
      font-weight: 600;
      font-size: 16px;

      span {
        font-size: var(--font-size__h4);
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    p {
      position: absolute;
      bottom: 100%;
      left: 0;
      margin: 0;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease-in-out;

      &.active {
        bottom: calc(100% + 1rem);
        opacity: 1;
        visibility: visible;
      }
    }
  }
`
