import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Layout from '../components/Layout'
import { StyledIndex } from '../assets/styles/templates/index.styles'

const IndexPage = () => {
  const [copied, setCopied] = useState(false)
  return (
    <Layout>
      <StyledIndex>
        <StaticImage
          alt="Smashcraft Minecraft Server"
          objectFit="cover"
          objectPosition="50% 50%"
          src="../assets/images/smashcraft-spawn-ss2.png"
        />
        <div className="container">
          <h1>
            Smashcraft is a Survival Multiplayer Minecraft Server{' '}
            <span>Est. 2011</span>
          </h1>
          <div className="blocks">
            <div className="block">
              <CopyToClipboard
                onCopy={() => setCopied(true)}
                text="mc.smashcraft.net"
              >
                <button>
                  <svg
                    fill="none"
                    viewBox="0 0 41 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1.141C0 .511.502 0 1.12 0h11.202c.62 0 1.12.511 1.12 1.141v12.555H1.12A1.13 1.13 0 0 1 0 12.554V1.141ZM27.557 1.141c0-.63.502-1.141 1.12-1.141H39.88C40.498 0 41 .511 41 1.141v11.413a1.13 1.13 0 0 1-1.12 1.142H27.557V1.14ZM13.443 13.696h14.114v21.456H13.443V13.696Z"
                      fill="#fff"
                    />
                    <path
                      d="M6.721 22.141c0-.63.502-1.141 1.12-1.141h5.602v19.859A1.13 1.13 0 0 1 12.323 42H7.841a1.13 1.13 0 0 1-1.12-1.141V22.14ZM27.557 21h5.601c.62 0 1.12.511 1.12 1.141V40.86c0 .63-.5 1.141-1.12 1.141h-4.48a1.13 1.13 0 0 1-1.12-1.141V21Z"
                      fill="#fff"
                    />
                  </svg>
                  <span>
                    Click to copy<span>mc.smashcraft.net</span>
                  </span>
                </button>
              </CopyToClipboard>
              <p className={copied ? `active` : ``}>Copied!</p>
            </div>
            <div className="block">
              <a className="block" href="https://discord.gg/7WZZCENqaN">
                <svg
                  fill="none"
                  viewBox="0 0 49 49"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.497 23.275a2.511 2.511 0 0 0 2.5 2.301 2.51 2.51 0 0 0 .001-5.018 2.61 2.61 0 0 0-2.499 2.721v-.006l-.002.002ZM28.94 20.56a2.732 2.732 0 0 1 2.497 2.72 2.731 2.731 0 0 1-2.487 2.719h-.01a2.61 2.61 0 0 1-2.502-2.608c0-.038 0-.075.003-.114v.006a2.59 2.59 0 0 1 2.485-2.728h.003l.01.005ZM3.062 5.047V49c6.172-5.455 4.2-3.648 11.369-10.315l-1.299 4.533h27.785a5.036 5.036 0 0 0 5.023-5.035V5.035A5.035 5.035 0 0 0 40.92 0H8.086a5.036 5.036 0 0 0-5.022 5.035v.012h-.002Zm6.983 23.226a32.675 32.675 0 0 1 3.614-14.467l-.086.184a12.014 12.014 0 0 1 6.856-2.571l.029-.002.343.392c-2.348.637-4.39 1.709-6.13 3.134l.029-.022c2.856-1.57 6.26-2.495 9.88-2.495 3.048 0 5.943.655 8.552 1.833l-.13-.053c.906.416 1.445.715 1.445.715a16.406 16.406 0 0 0-6.33-3.185l-.114-.025.245-.294a12.07 12.07 0 0 1 6.91 2.59l-.025-.018c2.148 4.157 3.442 9.06 3.528 14.255v.029c-1.631 2.268-4.263 3.728-7.234 3.728-.083 0-.167-.002-.25-.004h.012s-.907-1.102-1.642-2.034a7.655 7.655 0 0 0 4.273-2.87l.014-.02c-.359.253-.955.58-1 .612-2.468 1.372-5.414 2.178-8.55 2.178-2.432 0-4.75-.485-6.86-1.363l.118.043a14.115 14.115 0 0 1-2.867-1.477l.051.033a7.76 7.76 0 0 0 4.378 2.905l.053.01C18.422 30.946 17.54 32 17.54 32c-.067.002-.147.002-.227.002a8.94 8.94 0 0 1-7.252-3.701l-.018-.027h.002Z"
                    fill="#fff"
                  />
                </svg>
                <span>
                  Click to join<span>Discord</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </StyledIndex>
    </Layout>
  )
}

export default IndexPage
